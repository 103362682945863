
// axios
import axios from 'axios'


const request = axios.create({
  baseURL: 'http://192.168.1.6:9000/',
  timeout: 1000,
  withCredentials: true
})



export default request
